import { Bundle } from "@greeter/core";
import { QuantityPicks, VariantPicks } from "../models";

export function sumQuantity(quantityPicks?: QuantityPicks) {
  let alreadyPickedCount = 0;

  if (!quantityPicks) return alreadyPickedCount;

  for (const [_k2, v2] of quantityPicks.entries()) {
    alreadyPickedCount += v2.quantity;
  }
  return alreadyPickedCount;
}

export function sumPrice(bundle: Bundle, picks: VariantPicks) {
  let total = bundle.price;

  for (const pick of picks.values()) {
    for (const variantLine of pick.values()) {
      if (variantLine.additionalPrice) {
        total += variantLine.quantity * variantLine.additionalPrice.amount;
      }
    }
  }

  return total;
}
