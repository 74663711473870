import { ProductSchema } from "./Product";
import { z } from "zod";

export const BundleLineVariationSchema = z.object({
  id: z.string(),
  name: z.string(),
  additionalPrice: z.object({ amount: z.number() }), // Wrapping in an object since this allows for future multicurrency support.
  productId: z.string(),
});
export type BundleLineVariation = z.infer<typeof BundleLineVariationSchema>;

export const BundleLineSchema = z.object({
  id: z.string(),
  name: z.string(),
  quantity: z.number().gte(1).default(1),
  variations: z.array(BundleLineVariationSchema),
});
export type BundleLine = z.infer<typeof BundleLineSchema>;

export const BundleSchema = ProductSchema.extend({
  lines: z.array(BundleLineSchema),
  discountRate: z.optional(z.number().max(100).min(0).default(0)),
});
export type Bundle = z.infer<typeof BundleSchema>;

export function emptyBundle(): Bundle {
  return {
    id: crypto.randomUUID(),
    title: "",
    subTitle: "",
    description: "",
    lines: [],
    price: 0,
    coverUrl: "",
    priority: 0,
  };
}

export function isBundleDiscounted(b: Bundle): boolean {
  return !!b.discountPrice || !!b.discountRate;
}

export type BundlePrice =
  | { type: "regular"; price: number }
  | { type: "discounted"; price: number; discountedPrice: number };
/**
 * Function to calculate the actual price of a bundle;
 */
export function getBundlePrice(b: Bundle): BundlePrice {
  if (b.discountPrice) {
    return {
      type: "discounted",
      discountedPrice: b.discountPrice,
      price: b.price,
    };
  } else if (b.discountRate) {
    return {
      type: "discounted",
      discountedPrice: Number(
        (b.price * (1 - b.discountRate / 100)).toFixed(2)
      ),
      price: b.price,
    };
  }
  return { type: "regular", price: b.price };
}
