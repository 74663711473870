import React, { useContext } from "react";

type InputSectionContext = {
  open: boolean;
  disabled: boolean;
  toggle: () => void;
};
const InputSectionContext = React.createContext<InputSectionContext>({
  open: false,
  disabled: false,
  toggle: () => {},
});

export function useInputSectionContext() {
  return useContext(InputSectionContext);
}

export function InputSectionProvider(
  props: React.PropsWithChildren & { value: InputSectionContext }
) {
  return (
    <InputSectionContext.Provider value={props.value}>
      {props.children}
    </InputSectionContext.Provider>
  );
}
