import { useSelector } from "@xstate/react";
import css from "./CartContent.module.scss";
import { useCartActor } from "./CartProvider";
import { sumPrice } from "../BundlePicker/util";
import { ImageAsset } from "@greeter/core";
import { addOutline, removeOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

const priceFormatter = Intl.NumberFormat("da-DK", { maximumFractionDigits: 2 });

export type CartContentProps = {};
export function CartContent(props: CartContentProps) {
  const cart = useCartActor();
  const context = useSelector(cart, (s) => s.context);
  const cartItems = useSelector(cart, (s) => s.context.changes.value);

  const anyItems = Array.from(cartItems.values()).length > 0;

  return (
    <div className={css.CartContent}>
      {!anyItems && <div>Kurven er tom...</div>}
      {Array.from(cartItems.values()).map((ci) => {
        return (
          <div key={ci.id} className={css.CartLine}>
            <div className={css.CartLineThumb}>
              {ci.coverAsset && (
                <img
                  className="cover w-full"
                  src={ImageAsset.findUriWithSizeOrDefault(
                    ci.coverAsset,
                    "1x1-w256"
                  )}
                />
              )}
            </div>
            <div className={css.CartLineInfo}>
              <div className={css.CartLineTitle}>{ci.title}</div>
              <div className={css.CartLineType}>
                {ci.type === "bundle" && "Bordpakke"}
              </div>
              <div className={css.CartLinePrice}>
                {priceFormatter.format(ci.price)} kr.
              </div>
            </div>
            <div className={css.CartLineActions}>
              <div
                className={css.CartLineButton}
                onClick={() =>
                  cart.send({
                    type: "change",
                    data: { type: "dec", data: ci.id },
                  })
                }
              >
                <IonIcon icon={removeOutline} />
              </div>
              <div className={css.CartLineQuantity}>{ci.quantity}</div>
              <div
                className={css.CartLineButton}
                onClick={() =>
                  cart.send({
                    type: "change",
                    data: { type: "inc", data: ci.id },
                  })
                }
              >
                <IonIcon icon={addOutline} />
              </div>
            </div>
          </div>
        );
      })}
      {anyItems && (
        <div className={css.CartSummary}>
          <span>Pris:</span>
          <span>{context.total} kr.</span>
        </div>
      )}
    </div>
  );
}
