import React from "react";
import { classNames } from "@greeter/util";

import css from "./BottomBar.module.scss";

export type BottomBarProps = React.ComponentProps<"div"> & {};
export function BottomBar({ className, ...props }: BottomBarProps) {
  return <div {...classNames(className, css.BottomBar)} {...props} />;
}

export type BottomBarButtonProps = React.ComponentProps<"button">;
export function BottomBarButton({ className, ...props }: BottomBarButtonProps) {
  return <button {...classNames(className, css.BottomBarButton)} {...props} />;
}
