import { assign, ActorRefFrom, raise, setup } from "xstate";
import { Cart, CartItem, areCartItemsEqual, getProductId } from "./Cart";
import { zip, sortBy } from "lodash";
import { logger } from "@greeter/log";

const log = logger("[CartMachine]");

export type SetQuantityEvent = {
  type: "setQuantity";
  data: { productId: string; quantity: number };
};
export type IncrementQuantityEvent = {
  type: "incrementQuantity";
  data: { productId: string };
};
export type DecrementQuantityEvent = {
  type: "incrementQuantity";
  data: { productId: string };
};
export type ResetEvent = {
  type: "reset";
};
export type RefreshEvent = {
  type: "refresh";
};
export type ValidateEvent = {
  type: "validate";
};

export type ReloadCartEvent = {
  type: "reloadCart";
};

export type SetIdEvent = {
  type: "setId";
  data: string;
};

export type CartMachineEvents =
  | RefreshEvent
  | ReloadCartEvent
  | ResetEvent
  | SetQuantityEvent
  | SetIdEvent
  | ValidateEvent
  | IncrementQuantityEvent
  | DecrementQuantityEvent;

export type CartMachineContext = {
  id?: string;
  cart: Cart;
  cartItems: CartItem[];
};
export const cartMachine = setup({
  types: {} as {
    context: CartMachineContext;
    events: CartMachineEvents;
  },
}).createMachine({
  initial: "validating",
  predictableActionArguments: true,
  context: {
    cart: Cart.load(),
    cartItems: [],
  },
  entry: [
    assign({
      cart: ({ context: { id } }) => {
        const cart = Cart.load({ id });
        cart.subscribe(() => {
          raise({ type: "refresh" });
        });
        return cart;
      },
    }),
  ],
  on: {
    setId: {
      actions: [
        assign({ id: ({ event: ev }) => ev.data }),
        raise({ type: "reloadCart" }),
        raise({ type: "validate" }),
      ],
    },
    reloadCart: {
      actions: [
        assign({
          cart: ({ context: { id }, event: ev }) => {
            const cart = Cart.load({ id });
            log("Reloading cart", cart);
            cart.subscribe(() => {
              raise({ type: "refresh" });
            });
            return cart;
          },
        }),
        raise({ type: "validate" }),
      ],
    },
    refresh: {
      actions: [
        assign({
          cartItems: ({ context: { cart, cartItems } }) => {
            log("Cart items, updating");
            const newItems = cart.entries();

            if (cartItems.length !== newItems.length) {
              return newItems;
            }

            const zipped = zip(
              sortBy(cartItems, (item) => getProductId(item)),
              sortBy(newItems, (item) => getProductId(item))
            );

            let diff = false;
            for (const [a, b] of zipped) {
              if (!areCartItemsEqual(a, b)) {
                diff = true;
                break;
              }
            }

            return diff ? newItems : cartItems;
          },
        }),
        raise({ type: "validate" }),
      ],
    },
    setQuantity: {
      actions: [
        ({
          context: { cart },
          event: {
            data: { productId, quantity },
          },
        }) => {
          cart.set(productId, Math.max(0, quantity));
          cart.save();
        },
        raise({ type: "refresh" }),
        raise({ type: "validate" }),
      ],
    },
    incrementQuantity: {
      actions: [
        ({
          context: { cart },
          event: {
            data: { productId },
          },
        }) => {
          cart.add(productId);
          cart.save();
        },
        raise({ type: "refresh" }),
        raise({ type: "validate" }),
      ],
    },
  },
  states: {
    validating: {
      entry: raise({ type: "validate" }),
      on: {
        validate: [
          { target: "empty", guard: ({ context }) => context.cart.empty() },
          { target: "notEmpty" },
        ],
      },
    },
    notEmpty: {
      on: {
        validate: {
          target: "validating",
        },
      },
    },
    empty: {
      on: {
        validate: {
          target: "validating",
        },
      },
    },
  },
});

export type CartMachineRef = ActorRefFrom<typeof cartMachine>;
