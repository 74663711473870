import { TimeOfDayPeriod, TimeOfDayPeriodSchema } from "@greeter/date";
import { z } from "zod";

export const DailyOpeningHoursSchema = TimeOfDayPeriodSchema.extend({
  isOpen: z.boolean(),
});

export type DailyOpeningHours = TimeOfDayPeriod & {
  isOpen: boolean;
};
