import React from "react";

import { classNames } from "@greeter/util";
import { Button, ButtonProps } from "../Button";

import css from "./GradientButton.module.scss";

export function GradientButton({ className, ...props }: ButtonProps) {
  return <Button {...classNames(className, css.GradientButton)} {...props} />;
}
