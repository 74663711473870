import clamp from "lodash/clamp";

/**
 * Simple FIFO queue. Intends to simplify operations.
 *
 * Purpose is to make the regular array efficient by avoiding the use of
 * shift and unshift which is highly ineffecient as every such operation
 * copies the array.
 *
 * Instead this reverses the array and the pops which does not trigger a copy.
 */
export class PickingQueue<T> {
  private items: Array<T>;
  private _index: number = 0;

  constructor(items: Array<T> | PickingQueue<T>) {
    if (items instanceof PickingQueue) {
      this.items = items.items;
      this._index = items._index;
    } else {
      this.items = items;
    }
  }

  values() {
    return [...this.items];
  }

  static empty<T>(): PickingQueue<T> {
    return new PickingQueue<T>([]);
  }

  get current(): T | undefined {
    return this.items[this._index];
  }

  empty(): boolean {
    return this.items.length === 0;
  }

  prev(): T | undefined {
    this._index = clamp(this._index - 1, 0, this.items.length - 1);
    return this.items[this._index];
  }

  next(): T | undefined {
    this._index = clamp(this._index + 1, 0, this.items.length - 1);
    return this.items[this._index];
  }

  peekPrev(): T | undefined {
    return this.items.at(this._index - 1);
  }

  peekNext(): T | undefined {
    return this.items.at(this._index + 1);
  }

  isAtBeginning(): boolean {
    return this._index <= 0;
  }

  isAtEnd(): boolean {
    return this._index >= this.items.length - 1;
  }

  get position(): number {
    return this._index;
  }

  get length() {
    return this.items.length;
  }
}
