import { useActor, useMachine } from "@xstate/react";
import {
  BundlePickerApiRequirements,
  bundlePickerMachine,
  provideApiForMachine,
  VariantPickChanges,
} from "./BundlePickerMachine";
import css from "./BundlePicker.module.scss";
import { Bundle, getBundlePrice, ImageAsset } from "@greeter/core";
import { FloatingBackButton } from "@greeter/matter";
import { sumPrice, sumQuantity } from "./util";
import { useEffect, useRef } from "react";
import { useCartActor } from "../Cart/CartProvider";
import { BottomBar, BottomBarButton } from "../BottomBar";
import * as uuid from "uuid";
import { assert } from "../assert";
import { motion, AnimatePresence } from "motion/react";

const priceFormatter = Intl.NumberFormat("da-DK");

export type BundleItemProps = {
  bundle: Bundle;
  onClick?: () => void;
};
export function BundleItem(props: BundleItemProps) {
  const bundlePrice = getBundlePrice(props.bundle);

  return (
    <motion.div layout className={css.BundleItem} onClick={props.onClick}>
      <div className={css.Content}>
        <div className={css.Title}>{props.bundle.title}</div>
        <div className={css.ContentLines}>
          {props.bundle.lines.slice(0, 3).map((l) => {
            return (
              <div key={l.id} className={css.ContentLine}>
                {l.quantity} x {l.name}
              </div>
            );
          })}
        </div>
        <div className={css.Price}>
          {bundlePrice.type === "discounted" ? (
            <>
              <s>{priceFormatter.format(bundlePrice.price)}</s>{" "}
              {priceFormatter.format(bundlePrice.discountedPrice)} kr.
            </>
          ) : (
            <>{priceFormatter.format(bundlePrice.price)} kr.</>
          )}
        </div>
      </div>

      <div className={css.Cover}>
        <img
          src={
            props.bundle.coverAsset
              ? ImageAsset.findUriWithSizeOrDefault(
                  props.bundle.coverAsset,
                  "16x9-w512"
                )
              : props.bundle.coverUrl
          }
          className="cover w-full"
        />
      </div>
    </motion.div>
  );
}

export type VariantLineProps = {
  name: string;
  quantity: number;
  additionalPrice?: { amount: number };

  onIncrement: () => void;
  onDecrement: () => void;
};
export function VariantLine(props: VariantLineProps) {
  return (
    <div className={css.VariantLine}>
      <div className="flex-column" style={{ alignItems: "start" }}>
        <div className={css.VariantLineTitle}>{props.name}</div>
        {props.additionalPrice && props.additionalPrice?.amount > 0 && (
          <span style={{ fontSize: "0.9rem" }}>
            +{priceFormatter.format(props.additionalPrice.amount)} kr.
          </span>
        )}
      </div>
      <div className={css.VariantLineQuantity}>
        <div
          className={css.VariantLineQuantityButton}
          onClick={props.onDecrement}
        >
          -
        </div>
        <div className={css.VariantLineQuantityValue}>
          {props.quantity ?? 0}
        </div>
        <div
          className={css.VariantLineQuantityButton}
          onClick={props.onIncrement}
        >
          +
        </div>
      </div>
    </div>
  );
}

export type BundlePickerProps = {
  venueId: string;
  api: BundlePickerApiRequirements;

  onDismiss?: () => void;

  presentingElement?: HTMLElement;
};

export function BundlePicker(props: BundlePickerProps) {
  const cart = useCartActor();
  const [snap, send, m] = useActor(
    provideApiForMachine(bundlePickerMachine, props.api),
    { input: { venueId: props.venueId } }
  );
  function change(c: VariantPickChanges) {
    send({ type: "change", data: c });
  }
  useEffect(() => {
    const sub = m.on("bundlePicked", ({ data }) => {
      const bundle = snap.context.bundles.get(data.id);
      assert(bundle, "could not find bundle when adding picks to the cart");
      cart.send({
        type: "change",
        data: {
          type: "add",
          data: {
            id: uuid.v4(),
            quantity: 1,
            type: "bundle",
            picks: data.picks,
            price: sumPrice(bundle, data.picks),
            bundleId: data.id,
            title: bundle.title,
            coverAsset: bundle.coverAsset,
          },
        },
      });

      if (props.onDismiss) {
        props.onDismiss();
      }
    });

    return sub.unsubscribe;
  }, [snap, m]);

  const direction = useRef<"left" | "right">("right");

  return (
    <div className={css.BundlePicker}>
      <FloatingBackButton
        onClick={() => {
          if (snap.matches({ ui: "pickingVariants" })) {
            send({ type: "cancel" });
          } else if (props.onDismiss) {
            props.onDismiss();
          }
        }}
      />

      <motion.div className={css.Header} layout>
        <AnimatePresence>
          {snap.value.ui === "pickingBundle" ? (
            <motion.div
              key="pickingBundle"
              transition={{ ease: "easeInOut" }}
              layout
              initial={{
                opacity: 0,
                y: -100,
                x: 0,
              }}
              exit={{
                opacity: 0,
                scale: 0.7,
                position: "absolute",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 0,
              }}
              animate={{
                x: 0,
                y: 0,
                opacity: 1,
                zIndex: 1,
              }}
              style={{ overflow: "hidden", height: "100%" }}
            >
              <motion.img
                className="cover w-full"
                src={snap.context.venue?.coverAsset.uri}
              />
              <motion.h1 className={css.SectionTitle}>Vælg bordpakke</motion.h1>
            </motion.div>
          ) : (
            <motion.div
              key="pickingVariant"
              transition={{ ease: "easeInOut", duration: 0.2 }}
              layout
              initial={{
                opacity: 0,
                y: -100,
                x: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                zIndex: 1,
              }}
              exit={{
                position: "absolute",
                width: "100%",
                opacity: 0,
                y: -100,
                x: 0,
              }}
              style={{ overflow: "hidden", height: "100%" }}
            >
              <motion.img
                className="cover w-full"
                src={
                  snap.context.bundles.get(snap.context.bundleId!)?.coverAsset
                    ?.uri
                }
              />
              <motion.h1 className={css.SectionTitle}>Vælg varianter</motion.h1>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <motion.div
        className={css.Content}
        style={{ position: "relative", padding: "1rem" }}
        layout
      >
        <AnimatePresence>
          {snap.value.ui === "pickingBundle" ? (
            <motion.div
              layout="size"
              key="pickingBundle"
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              initial={{
                opacity: 0,
                x: direction.current === "right" ? 200 : -200,
                width: "100%",
              }}
              transition={{ ease: "easeInOut" }}
              animate={{
                x: 0,
                opacity: 1,
                zIndex: 1,
              }}
              exit={{
                position: "absolute",
                scale: 0.9,
                opacity: 0,
                top: 0,
                left: 0,
                zIndex: 0,
              }}
            >
              {[...snap.context.bundles.values()].map((b) => {
                return (
                  <BundleItem
                    key={b.id}
                    bundle={b}
                    onClick={() => send({ type: "pickBundle", data: b.id })}
                  />
                );
              })}
            </motion.div>
          ) : (
            <motion.div
              layout
              key={"pickingVariants-" + snap.context.pickingQueue.current}
              className={css.VariantPicker}
              initial={{
                opacity: 0,
                x: direction.current === "right" ? 100 : -100,
                y: 0,
                width: "100%",
                zIndex: 1,
                position: "absolute",
              }}
              transition={{ ease: "easeInOut" }}
              animate={{
                position: "relative",
                x: 0,
                opacity: 1,
                zIndex: 1,
              }}
              exit={{
                position: "absolute",
                width: "100%",
                scale: 0.7,
                opacity: 0,
                zIndex: 0,
              }}
            >
              <motion.h1>
                {snap.context.bundleLine?.name}{" "}
                {(
                  Array.from(
                    snap.context.changes.value
                      ?.get(snap.context.bundleLineId ?? "SHOULDFAIL")
                      ?.values() ?? []
                  ) ?? []
                ).reduce((acc, curr) => {
                  return acc + curr.quantity;
                }, 0)}{" "}
                ud af {snap.context.bundleLine?.quantity}
              </motion.h1>
              <motion.div className={css.VariantLines}>
                {!!snap.context.bundleLineId &&
                  snap.context.bundleLine?.variations.map((v) => {
                    const id = {
                      bundleLineId: snap.context.bundleLineId!,
                      bundleLineVariantId: v.id,
                    };
                    return (
                      <VariantLine
                        key={v.id}
                        name={v.name}
                        additionalPrice={v.additionalPrice}
                        onIncrement={() =>
                          change({
                            type: "incQuantity",
                            data: id,
                          })
                        }
                        onDecrement={() =>
                          change({ type: "decQuantity", data: id })
                        }
                        quantity={
                          snap.context.changes.value
                            ?.get(snap.context.bundleLineId ?? "")
                            ?.get(v.id)?.quantity ?? 0
                        }
                      />
                    );
                  })}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {snap.matches({ ui: "pickingVariants" }) && (
        <BottomBar>
          <BottomBarButton
            hidden={snap.context.pickingQueue.isAtBeginning()}
            disabled={snap.context.pickingQueue.isAtBeginning()}
            onClick={() => {
              direction.current = "left";
              send({ type: "prevLine" });
            }}
          >
            <p>← Tilbage</p>
          </BottomBarButton>
          <BottomBarButton
            onClick={() => {
              direction.current = "right";
              send({ type: "validate" });
              send({ type: "nextLine" });
            }}
            disabled={
              sumQuantity(
                snap.context.changes.value.get(snap.context.bundleLineId ?? "")
              ) <
              (snap.context.bundleLines.get(snap.context.bundleLineId ?? "")
                ?.quantity ?? 1)
            }
          >
            <p>
              Tilføj valgte{" "}
              <q style={{ fontWeight: "bold" }}>
                {snap.context.bundleLine?.name}
              </q>{" "}
              →
            </p>
          </BottomBarButton>
        </BottomBar>
      )}
    </div>
  );
}
