import React from "react";

import { cartMachine } from "./CartMachine";
import { createActorContext } from "@xstate/react";

export const CartActorContext = createActorContext(cartMachine);

export function CartActorProvider(props: React.PropsWithChildren) {
  return (
    <CartActorContext.Provider>{props.children}</CartActorContext.Provider>
  );
}

export function useCartActor() {
  return CartActorContext.useActorRef();
}
