import React, { useContext, useRef, useState } from "react";
import { IonContent, IonModal, IonPage } from "@ionic/react";
import { useActor, useSelector } from "@xstate/react";
import {
  BookingMachineApiRequirements,
  bookingMachineWithApi,
  PracticalInfoChange,
} from "./CreateBookingMachineV2";
import { useParams } from "react-router-dom";
import { CartActorProvider, useCartActor } from "./Cart/CartProvider";
import {
  Calendar,
  datesForMonth,
  NumberPicker,
  TimePicker,
  Spinner,
  BookTableBox,
  LazyCoverImage,
  FloatingBackButton,
  GradientButton,
  useHiddenTabBar,
} from "@greeter/matter";
import { useEffect } from "react";

import css from "./CreateBookingPageV2.module.scss";
import {
  InputSection,
  InputSectionContent,
  InputSectionSummary,
  InputSectionSummaryContent,
  InputSectionSummaryTitle,
} from "./InputSection";
import { TimeOfDay, TimeOfDayPeriod } from "@greeter/date";
import { getEnv } from "@greeter-guest/utility/ConfigHooks";
import { Venue } from "@greeter/core";
import {
  addHours,
  differenceInDays,
  getDaysInMonth,
  isSameDay,
} from "date-fns";
import { BundlePicker } from "./BundlePicker/BundlePicker";
import { CartBottomBar } from "./Cart/CartBottomBar";
import { CartContent } from "./Cart/CartContent";
import { classNames } from "@greeter/util";

const dateFormatter = Intl.DateTimeFormat("da-DK", {
  year: "numeric",
  day: "numeric",
  month: "short",
});

export type CreateBookingPageApiRequirements = BookingMachineApiRequirements;

export type CreateBookingPageV2Props = {
  venueId: string;
  api: BookingMachineApiRequirements;
};

export function CreateBookingPageV2(props: CreateBookingPageV2Props) {
  useHiddenTabBar();

  const env = getEnv();

  const [snap, send, m] = useActor(bookingMachineWithApi(props.api), {
    input: { venueId: props.venueId },
  });
  const practicalInfo = useSelector(
    m,
    (s) => s.context.practicalInfoChanges.value
  );

  useEffect(() => {
    const sub = m.subscribe((s) =>
      console.debug(`[${CreateBookingPageV2.name}][bookingMachine]`, s)
    );
    return sub.unsubscribe;
  }, [m]);

  function changePracticalInfo(change: PracticalInfoChange) {
    send({ type: "changePracticalInfo", data: change });
  }

  const openingHours =
    snap.context.venue &&
    snap.context.specialOpeningHours &&
    practicalInfo.arrivalDate
      ? Venue.findOpeningHoursAsDatePeriodForDate(
          snap.context.venue,
          practicalInfo.arrivalDate,
          snap.context.specialOpeningHours
        )
      : undefined;

  const cart = useCartActor();

  const pageRef = useRef<HTMLElement>(null);

  return (
    <IonPage ref={pageRef}>
      <IonContent>
        <div className={css.CreateBookingPage}>
          <div
            className={css.Cover}
            style={{ aspectRatio: "16 / 7", position: "relative" }}
          >
            <LazyCoverImage src={snap.context.venue?.coverAsset?.uri} />
            {snap.context.venue && (
              <h1
                style={{
                  position: "absolute",
                  bottom: "1rem",
                  left: "1rem",
                  margin: 0,
                }}
              >
                Book bord
              </h1>
            )}
          </div>
          {snap.matches({ ui: "practicalInfo" }) && (
            <div className={css.Form}>
              <InputSection
                invalid={
                  !practicalInfo.groupSize || practicalInfo.groupSize <= 0
                }
              >
                <InputSectionSummary>
                  <InputSectionSummaryTitle>Gæster</InputSectionSummaryTitle>
                  <NumberPicker
                    min={0}
                    value={practicalInfo.groupSize ?? 0}
                    onChange={(n) =>
                      changePracticalInfo({ type: "setGroupSize", data: n })
                    }
                  />
                </InputSectionSummary>
              </InputSection>

              {/* IDEA/TODO:a depends-on parameter? */}
              <InputSection
                disabled={
                  !practicalInfo.groupSize || practicalInfo.groupSize <= 0
                }
                invalid={!practicalInfo.arrivalDate}
              >
                <InputSectionSummary>
                  <InputSectionSummaryTitle>Dato</InputSectionSummaryTitle>
                  <InputSectionSummaryContent>
                    {practicalInfo.arrivalDate &&
                      dateFormatter.format(practicalInfo.arrivalDate)}
                    {practicalInfo.arrivalDate &&
                    practicalInfo.arrivalTime &&
                    !isSameDay(
                      practicalInfo.arrivalTime,
                      practicalInfo.arrivalDate
                    )
                      ? ` - ${dateFormatter.format(practicalInfo.arrivalTime)}`
                      : ""}
                  </InputSectionSummaryContent>
                </InputSectionSummary>
                <InputSectionContent>
                  <div style={{ padding: "1rem" }}>
                    <Calendar
                      unavailableDatesFn={(year, month) => {
                        const start = new Date(year, month);
                        const end = addHours(new Date(), 1);
                        const openingHours =
                          Venue.createDatePeriodsFromOpeningHours({
                            openingHours:
                              snap.context.venue!.bookableOpeningHours,
                            specialOpeningHours:
                              snap.context.specialOpeningHours,
                            range: differenceInDays(end, start),
                            now: start,
                          });
                        return openingHours.map((oh) => oh.from);
                      }}
                      datesFn={(year, month) => {
                        const start = new Date(year, month);
                        const openingHours =
                          Venue.createDatePeriodsFromOpeningHours({
                            openingHours:
                              snap.context.venue!.bookableOpeningHours,
                            specialOpeningHours:
                              snap.context.specialOpeningHours,
                            range: getDaysInMonth(start),
                            now: start,
                          });
                        return openingHours.map((oh) => oh.from);
                      }}
                      events={snap.context.greeterEvents}
                      selected={practicalInfo.arrivalDate}
                      onChange={function (d: Date): void {
                        changePracticalInfo({
                          type: "setArrivalDate",
                          data: d,
                        });
                      }}
                    />
                  </div>
                </InputSectionContent>
              </InputSection>

              <InputSection
                disabled={!practicalInfo.arrivalDate || !openingHours}
                invalid={!practicalInfo.arrivalTime}
              >
                <InputSectionSummary>
                  <InputSectionSummaryTitle>
                    Klokkeslæt
                  </InputSectionSummaryTitle>
                  <InputSectionSummaryContent>
                    {practicalInfo.arrivalTime && (
                      <span>
                        {TimeOfDay.fromDate(
                          practicalInfo.arrivalTime
                        ).toString()}
                      </span>
                    )}
                  </InputSectionSummaryContent>
                </InputSectionSummary>
                <InputSectionContent>
                  {/* TODO: Replace with actual time of day for a venue */}
                  <div style={{ padding: "1rem" }}>
                    {openingHours && (
                      <TimePicker
                        interval={30}
                        range={TimeOfDayPeriod.fromDatePeriod(openingHours)}
                        selected={
                          practicalInfo.arrivalTime &&
                          TimeOfDay.fromDate(practicalInfo.arrivalTime)
                        }
                        anchorDate={practicalInfo.arrivalDate}
                        onChangeDate={(d) =>
                          changePracticalInfo({
                            type: "setArrivalTime",
                            data: d,
                          })
                        }
                      />
                    )}
                  </div>
                </InputSectionContent>
              </InputSection>
              <InputSection
                disabled={!snap.context.floorPlan || !practicalInfo.arrivalTime}
                invalid={
                  practicalInfo.tables && practicalInfo.tables.length === 0
                }
              >
                <InputSectionSummary>
                  <InputSectionSummaryTitle>Plads</InputSectionSummaryTitle>
                  <InputSectionSummaryContent>
                    {practicalInfo.tables &&
                      Array.from(practicalInfo.tables)
                        .map((t) => t.tableNumber)
                        .join(", ")}
                  </InputSectionSummaryContent>
                </InputSectionSummary>
                <InputSectionContent>
                  {!!snap.context.floorPlan && (
                    <div style={{ padding: "1rem" }}>
                      <BookTableBox
                        env="dev" // TODO: REMEMBER TO FIX
                        now={practicalInfo.arrivalTime}
                        display="capacity"
                        // total={context.orderTotal}
                        bookedTables={
                          snap.context.bookedTables.concat(
                            snap.context.lockedTables
                          ) ?? []
                        }
                        floorPlan={snap.context.floorPlan ?? { areas: [] }}
                        groupSize={practicalInfo.groupSize ?? 0}
                        onTableClick={(area, table) => {
                          changePracticalInfo({
                            type: "selectTable",
                            data: { area: area, tableNumber: table.number },
                          });
                        }}
                        // onClick={handleBookTableBoxClick}
                        selectedTables={practicalInfo.tables}
                        // TODO: We should probably implement this. I don't remember why though...
                        // onSelectedTablesChange={(tableIds) => {
                        //   for (const tableId of tableIds) {
                        //     changePracticalInfo({
                        //       type: "selectTable",
                        //       data: tableId,
                        //     });
                        //   }
                        // }}
                        onMinimumPriceChange={(newMin) => {
                          changePracticalInfo({
                            type: "setMinPrice",
                            data: newMin,
                          });
                        }}
                        featureFlags={{ overlayOnOpeningHoursViolation: true }}
                        specialOpeningHours={
                          snap.context.specialOpeningHours ?? []
                        }
                      />
                    </div>
                  )}
                </InputSectionContent>
              </InputSection>
              <InputSection
                disabled={
                  !snap.context.bundles ||
                  !practicalInfo.tables ||
                  practicalInfo.tables?.length === 0
                }
                invalid={cart.getSnapshot().context.count === 0}
              >
                <InputSectionSummary
                  onClick={() => {
                    send({ type: "openBundlePickerModal" });
                  }}
                >
                  <InputSectionSummaryTitle>
                    Drikkevarer
                  </InputSectionSummaryTitle>
                  <InputSectionSummaryContent>
                    {cart.getSnapshot().context.count}
                  </InputSectionSummaryContent>
                </InputSectionSummary>
              </InputSection>
              {cart.getSnapshot().context.count > 0 &&
                !snap.matches({ modal: "cart" }) && (
                  <GradientButton
                    style={{
                      borderRadius: "10px",
                      minHeight: 60,
                      fontSize: "1.5rem",
                    }}
                    onClick={() => send({ type: "openCartModal" })}
                  >
                    Til kurven
                  </GradientButton>
                )}
            </div>
          )}
        </div>
        <IonModal
          className={css.UnfuckModal}
          isOpen={snap.matches({ modal: "bundlePicker" })}
          backdropDismiss
          canDismiss
          onWillDismiss={() => {
            if (snap.matches({ modal: "bundlePicker" })) {
              send({ type: "closeModal" });
            }
          }}
          mode="ios"
          breakpoints={[0.9]}
          presentingElement={pageRef.current!}
        >
          <BundlePicker
            onDismiss={() => {
              send({ type: "closeModal" });
            }}
            venueId={props.venueId}
            api={props.api}
          />
        </IonModal>
        {cart.getSnapshot().context.count > 0 && (
          <CartBottomBar
            onClick={() => {
              send({ type: "openCartModal" });
            }}
          />
        )}
        <IonModal
          {...classNames(css.UnfuckModal, css.CartModal)}
          isOpen={snap.matches({ modal: "cart" })}
          style={{ "--height": "auto" }}
          canDismiss
          backdropDismiss
          onWillDismiss={() => {
            if (snap.matches({ modal: "cart" })) {
              send({ type: "closeModal" });
            }
          }}
          mode="ios"
          breakpoints={[0.9]}
          presentingElement={pageRef.current!}
        >
          <div
            style={{
              padding: "1rem",
              margin: "0 auto",
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <FloatingBackButton
              onClick={() => {
                send({ type: "closeModal" });
              }}
            />
            <CartContent />
            <GradientButton
              style={{
                borderRadius: "10px",
                minHeight: 60,
                fontSize: "1.5rem",
              }}
            >
              Book bordet!
            </GradientButton>
          </div>
        </IonModal>
      </IonContent>
      <Spinner
        reason={
          snap.matches({ ui: "initializing" })
            ? "Gør klar..."
            : snap.matches({ background: { venue: "fetching" } })
            ? "Henter venue..."
            : snap.matches({
                background: { specialOpeningHours: "fetching" },
              }) && "Henter åbningstider..."
        }
      />
    </IonPage>
  );
}

export type CreateBookingPageV2WithProvidersProps = {
  venueId?: string;
  api: BookingMachineApiRequirements;
};
export function CreateBookingPageV2WithProviders(
  props: CreateBookingPageV2WithProvidersProps
) {
  const params = useParams<{ venueId: string }>();

  return (
    <CartActorProvider>
      <CreateBookingPageV2
        api={props.api}
        venueId={props.venueId ?? params.venueId}
      />
    </CartActorProvider>
  );
}
