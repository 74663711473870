export class AssertionError extends Error {
  constructor(reason?: string, private context?: any) {
    super(reason);
  }
}

/**
 * A useful tool to ensure invariants
 *
 * This function however sees empty strings and 0 as a TRUTHY VALUE contrary to what Javascript thinks.
 */
export function assert<T>(t?: T, msg?: string, ctx?: any): asserts t {
  if (!t && t !== 0 && t !== "") {
    throw new AssertionError(msg, ctx);
  }
}
