import { z } from "zod";

export function ChangeStateSchema<
  T extends z.ZodTypeAny,
  TChange extends z.ZodTypeAny
>(value: T, change: TChange) {
  const t = z.object({
    value: value,
    changes: z.array(change),
    index: z.number(),
  });

  return t;
}

// By using types like this we get easy copies
// for using it in React or with Xstate
export type ChangeState<T, TChange> = {
  value: T;
  index: number;
  changes: Array<TChange>;
};

export function createChangeState<T, TChange>(
  initial: T,
  changes: Array<TChange> = []
): ChangeState<T, TChange> {
  return {
    value: initial,
    index: 0,
    changes: changes,
  };
}

export function change<T, TChange>(
  state: ChangeState<T, TChange>,
  apply: (value: T, change: TChange) => void,
  change?: TChange
): ChangeState<T, TChange> {
  if (change) {
    state.changes.push(change);
  }

  const unapplied = state.changes.slice(state.index);

  for (let i = 0, l = unapplied.length; i < l; i++) {
    apply(state.value, unapplied[i]);
    state.index++;
  }

  return state;
}
