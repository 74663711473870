import { TimeOfDay } from "@greeter/date";
import { z } from "zod";

export const BookingSettingsSchema = z.object({
  buffer: z.string().transform((s) => TimeOfDay.parse(s)),
});

export type BookingSettings = {
  buffer: TimeOfDay;
};
