import { Config, Env } from "@greeter/config";
import config from "../../../config.json";
import env from "../../../environment.json";
import firebaseConfig from "../../../firebase-config";

export function getConfig() {
  return config as Config;
}

export function getFirebaseConfig() {
  return firebaseConfig;
}

export function getEnv() {
  return env.environment as Env;
}
