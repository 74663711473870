import { GreeterEvent, ImageAsset } from "@greeter/core";
import { DateOnlyString, Month, toDateOnlyString } from "@greeter/date";
import css from "./Calendar.module.scss";
import { useEffect, useMemo, useState } from "react";
import {
  addMonths,
  isSameDay,
  isSameMonth,
  isToday,
  subMonths,
} from "date-fns";
import { groupDatesByWeekday, indexEventsByDate, lookupEvent } from "./util";
import { classNames } from "@greeter/util";
import { IonIcon } from "@ionic/react";
import { chevronBack, chevronForward } from "ionicons/icons";

const yearMonthFormatter = Intl.DateTimeFormat("da-DK", {
  year: "numeric",
  month: "long",
});

const weekDayFormatter = Intl.DateTimeFormat("da-DK", { weekday: "long" });
const dateFormatter = Intl.DateTimeFormat("da-DK", { day: "numeric" });

const fullDateFormatter = Intl.DateTimeFormat("da-DK", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

const timeFormatter = Intl.DateTimeFormat("da-DK", {
  hourCycle: "h24",
  timeStyle: "short",
});

type Year = number;

export type CalendarProps = {
  unavailableDatesFn?: (year: Year, month: Month) => Array<Date>;
  /**
   * A function to generate the available dates for month and year
   */
  datesFn: (year: Year, month: Month) => Array<Date>;
  selected?: Date;
  onChange: (d: Date) => void;

  events?: Array<GreeterEvent>;
};

const danishWeek = [1, 7, 6, 5, 4, 3, 2];

export function Calendar(props: CalendarProps) {
  const indexedEvents = useMemo(
    () => indexEventsByDate(props.events ?? []),
    [props.events]
  );
  const [currentYearMonth, setCurrentYearMonth] = useState(new Date());

  // NOTE: Since we're looking this value up many times, we use a Set to near O(N) perf. on lookups
  const [unavailableDates, setUnavailableDates] = useState<Set<DateOnlyString>>(
    new Set()
  );
  const [dates, setDates] = useState(
    props.datesFn(currentYearMonth.getFullYear(), currentYearMonth.getMonth())
  );

  useEffect(() => {
    if (!props.unavailableDatesFn) return;

    const newUnavailableDates = props.unavailableDatesFn(
      currentYearMonth.getFullYear(),
      currentYearMonth.getMonth()
    );

    setUnavailableDates(new Set(newUnavailableDates.map(toDateOnlyString)));
  }, [currentYearMonth]);
  useEffect(() => {
    const newDates = props.datesFn(
      currentYearMonth.getFullYear(),
      currentYearMonth.getMonth()
    );

    setDates(newDates);
  }, [currentYearMonth]);

  const groupedByWeekdayDates = useMemo(
    () => groupDatesByWeekday(dates),
    [dates]
  );

  console.debug("grouped dates by weekdate", groupDatesByWeekday);

  function onBack() {
    setCurrentYearMonth((prev) => subMonths(prev, 1));
  }
  function onNext() {
    setCurrentYearMonth((prev) => addMonths(prev, 1));
  }

  const currentEvent = useMemo(() => {
    if (props.selected) {
      return lookupEvent(indexedEvents, props.selected);
    }
  }, [indexedEvents, currentYearMonth, props.selected]);

  return (
    <div className={css.Calendar}>
      <div className={css.YearMonthSelector}>
        <div className={css.Back} onClick={onBack}>
          <IonIcon icon={chevronBack} />
        </div>
        <div className={css.YearMonth}>
          {yearMonthFormatter.format(currentYearMonth)}
        </div>
        <div className={css.Forward} onClick={onNext}>
          <IonIcon icon={chevronForward} />
        </div>
      </div>

      <div className={css.DateSelector}>
        {Array.from(groupedByWeekdayDates.entries())
          .sort(([_da, a], [_db, b]) => {
            return (
              Number(
                a
                  .filter((d) => !unavailableDates.has(toDateOnlyString(d)))
                  .find((d) => d > new Date())
              ) -
              Number(
                b
                  .filter((d) => !unavailableDates.has(toDateOnlyString(d)))
                  .find((d) => d > new Date())
              )
            );
          })
          .map(([_day, dates]) => {
            return (
              <div key={_day} className={css.EventInfoContainer}>
                <div className={css.WeekdayContainer}>
                  <div className={css.Weekday}>
                    <h4 style={{ margin: 0, textTransform: "capitalize" }}>
                      {weekDayFormatter.format(dates[0] ?? new Date())}
                    </h4>
                    <div className={css.Dates}>
                      {dates.map((d) => {
                        const event = lookupEvent(indexedEvents, d);

                        return (
                          <div
                            key={`${_day}-${toDateOnlyString(d)}`}
                            className={css.DateContainer}
                          >
                            <div
                              {...classNames(
                                css.Date,
                                isToday(d) && css.Today, // TODO: This might be confusing if booking past midnight
                                props.selected &&
                                  isSameDay(props.selected, d) &&
                                  css.Active,
                                unavailableDates.has(toDateOnlyString(d)) &&
                                  css.Disabled,
                                event && css.Eventfull
                              )}
                              onClick={() => props.onChange?.call(null, d)}
                            >
                              {event && (
                                <img
                                  {...classNames(
                                    css.ThumbnailEventCover,
                                    "cover",
                                    "w-full"
                                  )}
                                  src={ImageAsset.findUriWithSizeOrDefault(
                                    event.coverAsset,
                                    "1x1-w100"
                                  )}
                                />
                              )}
                              <div className={css.DateContent}>
                                <span style={{ alignSelf: "end" }}>
                                  {isToday(d) ? "I dag" : d.getDate()}
                                </span>
                                {event ? (
                                  <div
                                    style={{ alignSelf: "start" }}
                                    {...classNames(css.EventDot)}
                                  />
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {_day === currentEvent?.startsAt.getDay() &&
                    isSameMonth(currentEvent.startsAt, currentYearMonth) && (
                      <div
                        style={{
                          backgroundColor: "var(--gm-color-default-bg)",
                          position: "relative",
                        }}
                      >
                        <div
                          className={css.EventCover}
                          style={{
                            backgroundImage: `url(${ImageAsset.findUriWithSizeOrDefault(
                              currentEvent?.coverAsset,
                              "16x9-w1024"
                            )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                        <div className={css.EventLabel}>Event</div>
                      </div>
                    )}
                </div>

                {currentEvent &&
                  _day === currentEvent?.startsAt.getDay() &&
                  isSameMonth(currentEvent.startsAt, currentYearMonth) && (
                    <div className={css.EventInfo}>
                      <h3
                        style={{ marginTop: 0 }}
                        className={css.EventInfoTitle}
                      >
                        {currentEvent.name}
                      </h3>

                      <div className={css.EventInfoDetails}>
                        <div className={css.EventInfoStartsAt}>
                          <b>Eventet starter: </b>
                          <span>
                            {timeFormatter
                              .format(currentEvent.startsAt)
                              .replace(".", ":")}
                          </span>
                        </div>
                      </div>
                      <div className={css.EventInfoDescription}>
                        {currentEvent.description.split("\n").map((s) => (
                          <p>{s}</p>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
