import { GreeterEvent } from "@greeter/core";
import { Day, Month } from "@greeter/date";
import { upsert } from "@greeter/map";
import { getDaysInMonth } from "date-fns";

export function groupDatesByWeekday(dates: Array<Date>): Map<Day, Array<Date>> {
  const m = new Map<Day, Array<Date>>();
  for (let i = 0, l = dates.length; i < l; i++) {
    const date = dates[i];
    upsert(m, date.getDay(), date);
  }
  return m;
}

export function datesForMonth(monthDate: Date) {
  const month: Array<Date> = [];
  for (let i = 1, l = getDaysInMonth(monthDate); i <= l; i++) {
    month.push(new Date(monthDate.getFullYear(), monthDate.getMonth(), i));
  }
  return month;
}

const dateOnlyFormatter = Intl.DateTimeFormat("da-DK", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

export function indexEventsByDate(
  events: Array<GreeterEvent>
): Map<string, GreeterEvent> {
  const m = new Map<string, GreeterEvent>();

  for (let i = 0, l = events.length; i < l; i++) {
    const event = events[i];
    m.set(dateOnlyFormatter.format(event.startsAt), event);
  }

  return m;
}

export function lookupEvent(
  m: Map<string, GreeterEvent>,
  d?: Date
): GreeterEvent | undefined {
  if (!d) {
    return undefined;
  }
  return m.get(dateOnlyFormatter.format(d));
}
