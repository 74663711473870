import { TimeOfDay, TimeOfDayPeriod } from "@greeter/date";
import { useMemo } from "react";
import { addSeconds, set } from "date-fns";
import { classNames } from "@greeter/util";

import css from "./TimePicker.module.scss";

export type TimePickerProps = {
  range: TimeOfDayPeriod;

  /**
   * interval in minutes
   */
  interval: number;

  selected?: TimeOfDay;
  onChange?: (tod: TimeOfDay) => void;

  /**
   * optional, might be useful to return the date from the anchor
   */
  anchorDate?: Date;
  onChangeDate?: (d: Date) => void;
};
export function TimePicker(props: TimePickerProps) {
  const anchor = useMemo(() => {
    return set(props.anchorDate ?? new Date(), {
      hours: props.range.from.hours,
      minutes: props.range.from.minutes,
      seconds: props.range.from.seconds,
      milliseconds: 0,
    });
  }, [props.anchorDate]);

  const tods = useMemo<Array<TimeOfDay>>(() => {
    const result: Array<TimeOfDay> = [];
    const start = props.range.from;
    const end = props.range.to;
    const diff = start.difference(end).getTotalSeconds() / 60;
    const interval = props.interval;

    for (let i = 0; i <= diff; i += interval) {
      result.push(start.addMinutes(i));
    }

    return result;
  }, [props.range, props.interval]);

  return (
    <div className={css.TimePicker}>
      {tods.map((tod) => {
        return (
          <div
            {...classNames(
              css.TimeOfDay,
              tod.toString() === props.selected?.toString() && css.Active
            )}
            onClick={() => {
              if (props.onChange) {
                props.onChange(tod);
              }

              if (anchor && props.onChangeDate) {
                const diff =
                  props.range.from?.difference(tod).getTotalSeconds() ?? 0;

                props.onChangeDate(addSeconds(anchor, diff));
              }
            }}
          >
            {tod.toString()}
          </div>
        );
      })}
    </div>
  );
}
