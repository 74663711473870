class AssertionError extends Error {
  constructor(reason?: string, private context?: any) {
    super(reason);
  }
}

/**
 * A useful tool to ensure invariants
 */
export function assert<T>(t?: T, msg?: string, ctx?: any): asserts t {
  if (!t) {
    throw new AssertionError(msg, ctx);
  }
}
