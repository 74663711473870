import { flatten, uniqBy } from "lodash";
import { z } from "zod";

export const TableIdSchema = z.object({
  area: z.string(),
  tableNumber: z.number(),
});

export type TableId = z.infer<typeof TableIdSchema>;

export const TableSchema = z.object({
  type: z.enum(["table", "pool-table", "football-table"]),
  number: z.number(),
  minCapacity: z.number(),
  maxCapacity: z.number(),
  minPrice: z.number(),
});

export type Table = z.infer<typeof TableSchema>;

export module Table {
  export function tableIdsToMap(tableIds: TableId[]) {
    return new Map<string, TableId[]>(
      tableIds.map((t) => [
        t.area,
        tableIds.filter((innerT) => innerT.area === t.area),
      ])
    );
  }

  export function flattenTableIds(map: Map<string, TableId[]>): TableId[] {
    return uniqBy(
      flatten([...Object.entries(map)].map(([k, v]) => v)),
      (t) => `${t.area}${t.tableNumber}`
    );
  }

  export function toTableId(area: string, table: Table): TableId {
    return {
      area: area,
      tableNumber: table.number,
    };
  }

  export function toTableIds(map: Map<string, Table[]>): TableId[] {
    // We can handle both maps and objects
    const entries: [string, Table[]][] =
      map instanceof Map
        ? [...map.entries()]
        : Object.entries(map);

    const mapped: TableId[] = [];

    // Unfolded the functional approach to allow for easy debugging
    // It's much easier to debug procedural code than expressionful code.
    for (const [area, tables] of entries) {
      for (const table of tables) {
        mapped.push({ area: area, tableNumber: table.number });
      }
    }

    return mapped;
  }

  export function eqIds(a: TableId, b: TableId) {
    return a.area === b.area && a.tableNumber === b.tableNumber;
  }
}
