import { IonIcon } from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";

import css from "./NumberPicker.module.scss";
import { clamp } from "lodash";

export type NumberPickerProps = {
  min?: number;
  value: number;
  onChange: (value: number) => void;
};

export function NumberPicker(props: NumberPickerProps) {
  return (
    <div className={css.NumberPicker}>
      <div className={css.InputContainer}>
        <input
          className={css.Input}
          type="text"
          inputMode="numeric"
          value={props.value}
          onChange={(ev) => {
            props.onChange(Number(ev.currentTarget.value) ?? 0);
          }}
        />
      </div>
      <div className={css.Controls}>
        <IonIcon
          className={css.Button}
          icon={removeOutline}
          onClick={() =>
            props.onChange(
              clamp(
                props.value - 1,
                props.min ?? Number.MIN_SAFE_INTEGER,
                Number.MAX_SAFE_INTEGER
              )
            )
          }
        />
        <IonIcon
          className={css.Button}
          icon={addOutline}
          onClick={() =>
            props.onChange(
              clamp(
                props.value + 1,
                props.min ?? Number.MIN_SAFE_INTEGER,
                Number.MAX_SAFE_INTEGER
              )
            )
          }
        />
      </div>
    </div>
  );
}
