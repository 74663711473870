import {
  Booking,
  BookingSchema,
  BookingSettingsSchema,
  Bundle,
  BundleSchema,
  FloorPlan,
  FloorPlanSchema,
  GreeterEventSchema,
  SpecialOpeningHoursSchema,
  Table,
  TableId,
  TableIdSchema,
  Venue,
  VenueSchema,
} from "@greeter/core";
import { assign, emit, enqueueActions, fromPromise, setup } from "xstate";
import {
  change,
  ChangeState,
  ChangeStateSchema,
  createChangeState,
} from "@greeter/changes";
import { typeToFlattenedError, z } from "zod";
import { NotImplementedError } from "@greeter/error";
import {
  ApiError,
  FetchBookedTables,
  FetchBooking,
  FetchBookingSettings,
  FetchBundlesByVenue,
  FetchFloorPlan,
  FetchLockedTables,
  FetchSpecialOpeningHours,
  FetchUpcomingGreeterEvents,
  FetchUpcomingGreeterEventsForVenue,
  FetchVenue,
} from "@greeter/api";
import { assert } from "@greeter/assert";

type TableIdString = `${number}-${string}`;
/**
 * Represent table id as a string, so it's easy to store in a Set, which makes it easy to toggle active and not
 */
function tableIdToString(tableId: TableId): TableIdString {
  return `${tableId.tableNumber}-${tableId.area}`;
}

function tableIdStringToTableId(tableId: TableIdString | string): TableId {
  const [tableNumber, ...area] = tableId.split("-");

  if (tableNumber === undefined || !area === undefined) {
    throw new Error(
      `Could not parse ${tableId} as TableId. Format should be <table-number>-<table-area>`
    );
  }

  return { tableNumber: Number(tableNumber), area: area.join("-") };
}

const PractialInfoFormSchema = z.object({
  groupSize: z.number().min(1).default(1),
  venueId: z.string(),
  comment: z.optional(z.string()),
  tables: z.array(TableIdSchema),
  minPrice: z.number().min(0).default(0),
  arrivalTime: z
    .date()
    .refine((a) => new Date() < a, "Du skal vælge et tidspunkt i fremtiden"),
  arrivalDate: z
    .date()
    .refine((a) => new Date() < a, "Du skal vælge et tidspunkt i fremtiden"),
});
type PracticalInfoForm = z.infer<typeof PractialInfoFormSchema>;

const QuantitySchema = z.number().min(1);
const CartLineSchema = z.object({
  bundleId: z.string(),
  bundleLines: z.map(
    z.string(),
    z.object({
      bundleLineId: z.string(),
      bundleLineVariants: z.map(z.string(), QuantitySchema),
    })
  ),
});
const CartFormSchema = z.object({
  lines: z.array(z.object({})),
});
type CartForm = z.infer<typeof CartFormSchema>;

const BookingFormSchema = z.intersection(
  PractialInfoFormSchema,
  CartFormSchema
);

type BookingForm = z.infer<typeof BookingFormSchema>;

type Quantity = number;
type BundleLineId = string;
type BundleLineVariantId = string;
type PickedBundle = {
  cartLineId: string;
  bundleId: string;
  lines: Map<BundleLineId, Map<BundleLineVariantId, Quantity>>;
};

/**
 * NOTE: We use zod here to make it easy to serialize and deserialize
 */
const PracticalInfoChangeSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("setComment"), data: z.string() }),
  z.object({
    type: z.literal("setArrivalTime"),
    data: z.string().transform((s) => new Date(s)),
  }),
  z.object({
    type: z.literal("setArrivalDate"),
    data: z.string().transform((s) => new Date(s)),
  }),
  z.object({ type: z.literal("selectTable"), data: TableIdSchema }),
  z.object({ type: z.literal("setGroupSize"), data: z.number() }),
  z.object({ type: z.literal("setMinPrice"), data: z.number() }),
]);

export type PracticalInfoChange = z.infer<typeof PracticalInfoChangeSchema>;

function applyPracticalInfoChange(
  form: Partial<PracticalInfoForm>,
  change: PracticalInfoChange
) {
  const logTag = `[${applyPracticalInfoChange.name}][${change.type}]`;
  console.debug(`${logTag}`, change);
  switch (change.type) {
    case "setGroupSize":
      form.groupSize = change.data;
      break;
    case "setArrivalDate":
      form.arrivalDate = change.data;
      form.arrivalTime = undefined;
      form.minPrice = 0;
      form.tables = [];
      break;
    case "setArrivalTime":
      form.arrivalTime = change.data;
      form.minPrice = 0;
      form.tables = [];
      break;
    case "setMinPrice":
      form.minPrice = change.data;
      break;
    case "selectTable":
      form.tables ??= [];

      const idx = form.tables.findIndex(
        (t) =>
          t.tableNumber === change.data.tableNumber &&
          t.area === change.data.area
      );
      if (idx > -1) {
        form.tables.splice(idx);
        break;
      }

      form.tables.push(change.data);
      break;
  }
}

type BookingEmitted =
  | { type: "creatingBooking" }
  | { type: "createdBooking" }
  | { type: "failedToFetchBundles"; error: ApiError }
  | { type: "failedToFetchVenue"; error: ApiError }
  | { type: "failedToFetchFloorPlan"; error: ApiError }
  | { type: "failedToFetchBookedTables"; error: ApiError }
  | { type: "failedToFetchLockedTables"; error: ApiError }
  | { type: "failedToFetchBooking"; error: ApiError }
  | { type: "failedToFetchBookingSettings"; error: ApiError }
  | { type: "failedToFetchGreeterEvents"; error: ApiError }
  | { type: "failedToFetchSpecialOpeningHours"; error: ApiError };

type BookingEvents =
  | { type: "changePracticalInfo"; data: PracticalInfoChange }
  | { type: "openBundlePickerModal" }
  | { type: "closeModal" }
  | { type: "openCartModal" }
  | { type: "next" }
  | { type: "prev" }
  | { type: "validate" };

const BookingContextSchema = z.object({
  venueId: z.string(),
  venue: VenueSchema.optional(),
  bookedTables: z.array(TableIdSchema),
  lockedTables: z.array(TableIdSchema),
  floorPlan: FloorPlanSchema.optional(),
  specialOpeningHours: z.array(SpecialOpeningHoursSchema),
  booking: BookingSchema.optional(),
  bundles: z.array(BundleSchema),
  bookingSettings: BookingSettingsSchema.optional(),
  greeterEvents: z.array(GreeterEventSchema),

  practicalInfoChanges: ChangeStateSchema(
    PractialInfoFormSchema.partial(),
    PracticalInfoChangeSchema
  ),

  practicalInfoValidationErrors: z
    .any()
    .transform((o) => o as typeToFlattenedError<PracticalInfoForm>)
    .optional(),
});

type BookingContext = z.infer<typeof BookingContextSchema>;

export interface BookingMachineApiRequirements {
  fetchBooking: FetchBooking;
  fetchBundles: FetchBundlesByVenue;
  fetchVenue: FetchVenue;
  fetchLockedTables: FetchLockedTables;
  fetchBookedTables: FetchBookedTables;
  fetchFloorPlan: FetchFloorPlan;
  fetchSpecialOpeningHours: FetchSpecialOpeningHours;
  fetchBookingSettings: FetchBookingSettings;
  fetchUpcomingGreeterEvents: FetchUpcomingGreeterEventsForVenue;
}

export function bookingMachineWithApi(api: BookingMachineApiRequirements) {
  return bookingMachine.provide({
    actors: {
      fetchVenue: fromPromise(({ input: { venueId } }) =>
        api.fetchVenue(venueId)
      ),
      fetchBooking: fromPromise(({ input: { bookingId } }) =>
        api.fetchBooking(bookingId)
      ),
      fetchBundles: fromPromise(({ input: { venueId } }) =>
        api.fetchBundles(venueId)
      ),
      fetchFloorPlan: fromPromise(({ input: { venueId } }) =>
        api.fetchFloorPlan(venueId)
      ),
      fetchBookedTables: fromPromise(({ input: { venueId, arrivalDate } }) =>
        api.fetchBookedTables(venueId, arrivalDate)
      ),
      fetchLockedTables: fromPromise(({ input: { venueId, arrivalDate } }) =>
        api.fetchLockedTables(venueId, arrivalDate)
      ),
      fetchSpecialOpeningHours: fromPromise(({ input: { venueId } }) =>
        api.fetchSpecialOpeningHours(venueId)
      ),
      fetchBookingSettings: fromPromise(({ input: { venueId } }) =>
        api.fetchBookingSettings(venueId)
      ),
      fetchUpcomingGreeterEvents: fromPromise(({ input: { venueId } }) =>
        api.fetchUpcomingGreeterEvents({ venueId })
      ),
    },
  });
}

export const bookingMachine = setup({
  types: {} as {
    context: BookingContext;
    events: BookingEvents;
    emitted: BookingEmitted;
    input: { venueId: string; bookingId?: string };
  },
  actors: {
    fetchFloorPlan: fromPromise(async function (args: {
      input: { venueId: string };
    }): ReturnType<BookingMachineApiRequirements["fetchFloorPlan"]> {
      throw new NotImplementedError();
    }),
    fetchBooking: fromPromise(async function (args: {
      input: { bookingId: string };
    }): ReturnType<BookingMachineApiRequirements["fetchBooking"]> {
      throw new NotImplementedError();
    }),
    fetchVenue: fromPromise(async function (args: {
      input: { venueId: string };
    }): ReturnType<BookingMachineApiRequirements["fetchVenue"]> {
      throw new NotImplementedError();
    }),
    fetchBundles: fromPromise(async function (args: {
      input: { venueId: string };
    }): ReturnType<BookingMachineApiRequirements["fetchBundles"]> {
      throw new NotImplementedError();
    }),
    fetchBookedTables: fromPromise(async function (args: {
      input: { venueId: string; arrivalDate: Date };
    }): ReturnType<BookingMachineApiRequirements["fetchBookedTables"]> {
      throw new NotImplementedError();
    }),
    fetchLockedTables: fromPromise(async function (args: {
      input: { venueId: string; arrivalDate: Date };
    }): ReturnType<BookingMachineApiRequirements["fetchLockedTables"]> {
      throw new NotImplementedError();
    }),
    fetchSpecialOpeningHours: fromPromise(async function (args: {
      input: { venueId: string };
    }): ReturnType<BookingMachineApiRequirements["fetchSpecialOpeningHours"]> {
      throw new NotImplementedError();
    }),
    fetchBookingSettings: fromPromise(async function (args: {
      input: { venueId: string };
    }): ReturnType<BookingMachineApiRequirements["fetchBookingSettings"]> {
      throw new NotImplementedError();
    }),
    fetchUpcomingGreeterEvents: fromPromise(async function (args: {
      input: { venueId: string };
    }): ReturnType<
      BookingMachineApiRequirements["fetchUpcomingGreeterEvents"]
    > {
      throw new NotImplementedError();
    }),
  },
}).createMachine({
  type: "parallel",
  context: ({ input }) => {
    return {
      venueId: input.venueId,
      bundles: [],
      bookedTables: [],
      lockedTables: [],
      specialOpeningHours: [],
      greeterEvents: [],
      practicalInfoChanges: createChangeState<
        Partial<PracticalInfoForm>,
        PracticalInfoChange
      >({ venueId: input.venueId }),
    };
  },
  on: {
    changePracticalInfo: {
      actions: assign(({ event, context }) => {
        const newState = change(
          context.practicalInfoChanges,
          applyPracticalInfoChange,
          event.data
        );
        return {
          practicalInfoChanges: newState,
        };
      }),
    },
    validate: {
      actions: assign(({ context }) => {
        return {
          practicalInfoValidationErrors: PractialInfoFormSchema.safeParse(
            context.practicalInfoChanges.value
          ).error?.flatten(),
        };
      }),
    },
  },
  states: {
    background: {
      type: "parallel",
      states: {
        specialOpeningHours: {
          initial: "fetching",
          states: {
            idle: {},
            fetching: {
              invoke: {
                src: "fetchSpecialOpeningHours",
                input: ({ context }) => ({ venueId: context.venueId }),
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not an ApiError",
                      event.error
                    );
                    return {
                      type: "failedToFetchSpecialOpeningHours",
                      error: event.error,
                    };
                  }),
                },
                onDone: {
                  target: "idle",
                  actions: assign(({ event }) => {
                    return { specialOpeningHours: event.output };
                  }),
                },
              },
            },
          },
        },
        greeterEvents: {
          initial: "fetching",
          states: {
            idle: {},
            fetching: {
              invoke: {
                src: "fetchUpcomingGreeterEvents",
                input: ({ context }) => ({ venueId: context.venueId }),
                onError: {
                  target: "idle",
                  // @ts-ignore
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not an ApiError",
                      event.error
                    );
                    return {
                      type: "failedtoFetchGreeterEvents",
                      error: event.error,
                    };
                  }),
                },
                onDone: {
                  target: "idle",
                  actions: assign(({ event }) => {
                    return { greeterEvents: event.output };
                  }),
                },
              },
            },
          },
        },
        bookingSettings: {
          initial: "fetching",
          states: {
            idle: {},
            fetching: {
              invoke: {
                src: "fetchBookingSettings",
                input: ({ context }) => ({ venueId: context.venueId }),
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not an ApiError",
                      event.error
                    );
                    return {
                      type: "failedToFetchBookingSettings",
                      error: event.error,
                    };
                  }),
                },
                onDone: {
                  target: "idle",
                  actions: assign(({ event }) => {
                    return { bookingSettings: event.output };
                  }),
                },
              },
            },
          },
        },
        booking: {
          // TODO: Implement
          initial: "idle",
          states: {
            idle: {},
            fetching: {},
            creating: {},
          },
        },
        payment: {
          initial: "idle",
          states: {
            idle: {},
            creating: {},
          },
        },
        bundles: {
          initial: "fetching",
          states: {
            idle: {},
            fetching: {
              invoke: {
                src: "fetchBundles",
                input: ({ context }) => ({ venueId: context.venueId }),
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not an ApiError",
                      event.error
                    );
                    return { type: "failedToFetchBundles", error: event.error };
                  }),
                },
                onDone: {
                  target: "idle",
                  actions: assign(({ event }) => {
                    return { bundles: event.output };
                  }),
                },
              },
            },
          },
        },
        venue: {
          initial: "fetching",
          states: {
            idle: {},
            fetching: {
              invoke: {
                src: "fetchVenue",
                input: ({ context }) => ({ venueId: context.venueId }),
                onDone: {
                  target: "idle",
                  actions: assign(({ event }) => ({ venue: event.output })),
                },
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not of type ApiError",
                      event.error
                    );
                    return { type: "failedToFetchVenue", error: event.error };
                  }),
                },
              },
            },
          },
        },
        floorPlan: {
          initial: "fetching",
          states: {
            idle: {},
            fetching: {
              invoke: {
                src: "fetchFloorPlan",
                input: ({ context }) => ({ venueId: context.venueId }),
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not of type ApiError",
                      event.error
                    );
                    return {
                      type: "failedToFetchFloorPlan",
                      error: event.error,
                    };
                  }),
                },
                onDone: {
                  actions: assign(({ event }) => ({ floorPlan: event.output })),
                },
              },
            },
          },
        },
        bookedTables: {
          initial: "idle",
          states: {
            idle: {
              after: {
                1_000: {
                  target: "fetching",
                  guard({ context }) {
                    return !!context.practicalInfoChanges.value.arrivalDate;
                  },
                },
              },
            },
            fetching: {
              invoke: {
                src: "fetchBookedTables",
                input: ({ context }) => {
                  assert(
                    context.practicalInfoChanges.value.arrivalDate,
                    "cannot fetch booked tables when arrivalDate has not been set"
                  );
                  return {
                    venueId: context.venueId,
                    arrivalDate: context.practicalInfoChanges.value.arrivalDate,
                  };
                },
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not of type ApiError",
                      event.error
                    );
                    return {
                      type: "failedToFetchBookedTables",
                      error: event.error,
                    };
                  }),
                },
                onDone: {
                  actions: assign(({ event }) => ({
                    bookedTables: event.output,
                  })),
                },
              },
            },
          },
        },
        lockedTables: {
          initial: "idle",
          states: {
            idle: {
              after: {
                1_000: {
                  target: "fetching",

                  guard({ context }) {
                    return !!context.practicalInfoChanges.value.arrivalDate;
                  },
                },
              },
            },
            fetching: {
              invoke: {
                src: "fetchLockedTables",
                input: ({ context }) => {
                  assert(
                    context.practicalInfoChanges.value.arrivalDate,
                    "cannot fetch locked tables when arrivalDate has not been set"
                  );
                  return {
                    venueId: context.venueId,
                    arrivalDate: context.practicalInfoChanges.value.arrivalDate,
                  };
                },
                onError: {
                  target: "idle",
                  actions: emit(({ event }) => {
                    assert(
                      event.error instanceof ApiError,
                      "error is not of type ApiError",
                      event.error
                    );
                    return {
                      type: "failedToFetchLockedTables",
                      error: event.error,
                    };
                  }),
                },
                onDone: {
                  actions: assign(({ event }) => ({
                    lockedTables: event.output.map((lt) => lt.table),
                  })),
                },
              },
            },
          },
        },
      },
    },
    modal: {
      initial: "closed",
      states: {
        closed: {
          on: {
            openBundlePickerModal: "bundlePicker",
            openCartModal: "cart",
          },
        },
        cart: {
          on: { closeModal: "closed" },
        },
        bundlePicker: {
          on: {
            closeModal: "closed",
          },
        },
      },
    },
    ui: {
      initial: "initializing",
      states: {
        // TODO: Should figure out if we're already handling a booking that is waiting for payment confirmation
        initializing: {
          after: { 1_000: "practicalInfo" }, // TODO: Placeholder until API is in place
        },

        practicalInfo: {
          on: { next: "pickingProducts" },
        },

        pickingProducts: {
          // on: { next: { target: "checkout", guard: (context) => {} } },
        },
        checkingOut: {},

        paying: {},

        // When we return from payment flow
        completingBooking: {},
        // When we return from payment flow
        completedBooking: {},
      },
    },
  },
});
