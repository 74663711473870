import { cartOutline } from "ionicons/icons";
import { CartActorContext } from "./CartProvider";
import { IonIcon } from "@ionic/react";
import { BottomBar, BottomBarProps } from "../BottomBar";

const priceFormatter = Intl.NumberFormat("da-DK", { maximumFractionDigits: 2 });

export function CartBottomBar(props: BottomBarProps) {
  const count = CartActorContext.useSelector((s) => s.context.count);
  const total = CartActorContext.useSelector((s) => s.context.total);

  return (
    <BottomBar {...props} style={{ cursor: "pointer" }}>
      <div
        className="flex-row w-full"
        style={{
          justifyContent: "space-between",
          padding: "0 1rem",
          maxWidth: 600,
        }}
      >
        <div
          className="flex-row w-full"
          style={{
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <IonIcon
            style={{ width: "30px", height: "30px", color: "white" }}
            icon={cartOutline}
          />
          <span>{count ?? 0} i kurven</span>
        </div>
        <div style={{ display: "grid", placeItems: "center" }}>
          <span style={{ whiteSpace: "nowrap" }}>
            {priceFormatter.format(total ?? 0)} kr.
          </span>
        </div>
      </div>
    </BottomBar>
  );
}
