import { classNames } from "@greeter/util";
import React, { useContext, useState } from "react";

import css from "./InputSection.module.scss";
import {
  InputSectionProvider,
  useInputSectionContext,
} from "./InputSectionProvider";
import { checkmarkCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

export function InputSectionSummaryTitle(props: React.ComponentProps<"div">) {
  return <div {...props} />;
}
export function InputSectionSummaryContent({
  className,
  ...props
}: React.ComponentProps<"div">) {
  return (
    <div
      {...classNames(className, css.InputSectionSummaryContent)}
      {...props}
    />
  );
}
export function InputSectionSummary({
  className,
  onClick,
  ...props
}: React.ComponentProps<"div">) {
  const context = useInputSectionContext();
  return (
    <div
      {...classNames(
        className,
        css.InputSectionSummary,
        context.disabled && css.Disabled
      )}
      onClick={(ev) => {
        if (onClick) {
          onClick(ev);
        }
        if (!context.open) {
          const ionContent: HTMLIonContentElement | null =
            ev.currentTarget.closest("ion-content");
          const sectionParent: HTMLDivElement | null = ev.currentTarget.closest(
            `.${css.InputSection}`
          );
          const hasContent = sectionParent?.querySelector(
            `.${css.InputSectionContent}`
          );
          setTimeout(() => {
            ionContent?.getScrollElement().then((el) => {
              if (sectionParent && !!hasContent) {
                el.scrollTo({
                  top: sectionParent.offsetTop - 20,
                  behavior: "smooth",
                });
              }
            });
          }, 100);
        }

        context.toggle();
      }}
      {...props}
    />
  );
}

export function InputSectionContent({
  className,
  ...props
}: React.ComponentProps<"div">) {
  const context = useInputSectionContext();
  return (
    <div
      {...classNames(css.InputSectionContent, context.open && css.Open)}
      {...props}
    />
  );
}

export type InputSectionProps = React.PropsWithChildren & {
  onOpen?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  invalid?: boolean;
};
export function InputSection(props: InputSectionProps) {
  const [open, setOpen] = useState(false);
  const [firstInteraction, setFirstInteraction] = useState(false);

  return (
    <InputSectionProvider
      value={{
        open: open,
        disabled: props.disabled ?? false,
        toggle() {
          setOpen((prev) => !prev);
        },
      }}
    >
      <div
        onClick={() => {
          setFirstInteraction(true);
        }}
        {...classNames(
          css.InputSection,
          open && css.Open,
          firstInteraction && props.invalid && "has-error",
          props.disabled && css.Disabled
        )}
      >
        {firstInteraction && !props.invalid && (
          <IonIcon className={css.ValidIcon} icon={checkmarkCircle} />
        )}
        {props.children}
      </div>
    </InputSectionProvider>
  );
}
