import { Bundle, BundleLine, BundleLineVariation, Venue } from "@greeter/core";
import { FetchBundlesByVenue, FetchVenue } from "@greeter/api";
import { NotImplementedError } from "@greeter/error";
import { PickingQueue } from "./PickingQueue";
import { assign, enqueueActions, fromPromise, raise, setup } from "xstate";
import { clamp } from "lodash";
import { change, ChangeState, createChangeState } from "@greeter/changes";
import {
  BundleId,
  BundleLineId,
  BundleLineVariantId,
  Quantity,
  VariantPicks,
} from "../models";
import { assert } from "../assert";
import { sumQuantity } from "./util";

export type PickedBundle = {
  cartLineId: string;
  bundleId: string;
  lines: Map<BundleLineId, Map<BundleLineVariantId, Quantity>>;
};

export interface BundlePickerApiRequirements {
  fetchBundles: FetchBundlesByVenue;
  fetchVenue: FetchVenue;
}

export type BundlePickerContext = {
  venueId: string;
  venue?: Venue;

  changes: ChangeState<VariantPicks, VariantPickChanges>;

  /**
   * Indexes for quick lookup of data.
   */
  bundles: Map<BundleId, Bundle>;
  bundleLines: Map<BundleLineId, BundleLine>;
  bundleLineVariants: Map<BundleLineVariantId, BundleLineVariation>;

  bundleId?: BundleId;

  bundleLine?: BundleLine;
  bundleLineId?: BundleLineId;

  // For each peach this is slowly emptied untill all have been picked.
  pickingQueue: PickingQueue<BundleLineId>;
};

export type VariantPickChanges =
  | {
      type: "incQuantity";
      data: {
        bundleLineId: BundleLineId;
        bundleLineVariantId: BundleLineVariantId;
      };
    }
  | {
      type: "decQuantity";
      data: {
        bundleLineId: BundleLineId;
        bundleLineVariantId: BundleLineVariantId;
      };
    }
  | {
      type: "setQuantity";
      data: { bundleLineVariantId: BundleLineVariantId; quantity: Quantity };
    };

function setupApplyVariantPickChange(
  bundleLines: Map<BundleLineId, BundleLine>,
  bundleLineVariants: Map<BundleLineVariantId, BundleLineVariation>
) {
  return function applyVariantPickChange(
    variantPicks: VariantPicks,
    change: VariantPickChanges
  ) {
    const tag = `[${applyVariantPickChange.name}][${change.type}]`;
    console.log(`${tag}[before]`, bundleLines, variantPicks, change);
    switch (change.type) {
      case "incQuantity": {
        let bundleLinePicks =
          variantPicks.get(change.data.bundleLineId) ?? new Map();

        const bundleLine = bundleLines.get(change.data.bundleLineId);
        assert(
          bundleLine,
          `failed to find bundleline id with id ${change.data.bundleLineId}`,
          { tag, change }
        );

        const alreadyPickedCount = sumQuantity(bundleLinePicks);
        if (alreadyPickedCount === bundleLine.quantity) {
          break;
        }

        const variant = bundleLineVariants.get(change.data.bundleLineVariantId);
        assert(variant, "could not find variant", { tag, change });

        const pick = bundleLinePicks.get(change.data.bundleLineVariantId) ?? {
          quantity: 0,
          additionalPrice: 0,
        };

        pick.quantity += 1;
        pick.additionalPrice = variant.additionalPrice;
        bundleLinePicks.set(change.data.bundleLineVariantId, pick);

        variantPicks.set(change.data.bundleLineId, bundleLinePicks);
        break;
      }
      case "decQuantity": {
        const bundleLinePicks = variantPicks.get(change.data.bundleLineId);
        if (!bundleLinePicks) break;

        const pick = bundleLinePicks.get(change.data.bundleLineVariantId) ?? {
          quantity: 0,
          additionalPrice: 0,
        };

        const bundleLine = bundleLines.get(change.data.bundleLineId);
        assert(
          bundleLine,
          `failed to find bundleline id with id ${change.data.bundleLineId}`,
          { tag, change }
        );

        const bundleLineVariant = bundleLineVariants.get(
          change.data.bundleLineVariantId
        );
        assert(
          bundleLineVariant,
          `failed to find bundle line variant with id ${change.data.bundleLineVariantId} when decrementing quantity`
        );

        pick.quantity = clamp(
          0,
          pick.quantity ? pick.quantity - 1 : 0,
          bundleLine.quantity
        );
        pick.additionalPrice = bundleLineVariant?.additionalPrice.amount;
        break;
      }
      // TODO: These should be abstracted to a cart?
      // case "removeCartLine": {
      // }
      // case "addCartLine": {
      // }
      default:
        break;
    }
  };
}

export type BundlePickerEvents =
  | { type: "back" }
  | { type: "cancel" }
  | { type: "nextLine" }
  | { type: "pickBundle"; data: BundleId }
  | { type: "pickVariants"; data: BundleLineId }
  | { type: "finishPicking" }
  | { type: "change"; data: VariantPickChanges }
  // | {
  //     type: "addCartLine";
  //     data: Map<
  //       BundleId,
  //       Map<BundleLineId, Map<BundleLineVariantId, Quantity>>
  //     >;
  //   }
  // | { type: "removeCartLine"; data: CartLineId }
  | { type: "openCartSummary" }
  | { type: "closeCartSummary" }
  | { type: "validate" }
  | { type: "finish" };

export type BundlePickerEmitted = {
  type: "finished";
  data: { pickedBundle: PickedBundle };
};

export function provideApiForMachine(
  m: typeof bundlePickerMachine,
  api: BundlePickerApiRequirements
) {
  return m.provide({
    actors: {
      fetchBundles: fromPromise(
        ({ input }): ReturnType<BundlePickerApiRequirements["fetchBundles"]> =>
          api.fetchBundles(input.venueId)
      ),
      fetchVenue: fromPromise(
        ({ input }): ReturnType<BundlePickerApiRequirements["fetchVenue"]> =>
          api.fetchVenue(input.venueId)
      ),
    },
  });
}

function indexBundles(bundles: Array<Bundle>): Map<BundleId, Bundle> {
  const m = new Map<BundleId, Bundle>();

  for (const b of bundles) {
    m.set(b.id, b);
  }

  return m;
}

function indexBundleLines(
  bundles: Array<Bundle>
): Map<BundleLineId, BundleLine> {
  const m = new Map<BundleLineId, BundleLine>();
  for (const b of bundles) {
    for (const bl of b.lines) {
      m.set(bl.id, bl);
    }
  }
  return m;
}

function indexBundleLineVariants(
  bundles: Array<Bundle>
): Map<BundleLineVariantId, BundleLineVariation> {
  const m = new Map<BundleLineVariantId, BundleLineVariation>();
  for (const b of bundles) {
    for (const bl of b.lines) {
      for (const blv of bl.variations) {
        m.set(blv.id, blv);
      }
    }
  }
  return m;
}

function resetPicks(): Partial<BundlePickerContext> {
  return {
    bundleLineId: undefined,
    changes: createChangeState(new Map()),
    pickingQueue: PickingQueue.empty(),
  };
}

export const bundlePickerMachine = setup({
  types: {} as {
    input: { venueId: string };
    emitted: {
      type: "bundlePicked";
      data: { id: BundleId; picks: VariantPicks };
    };
    context: BundlePickerContext;
  },
  guards: {
    isAtEndOfPickingQueue({ context }) {
      return context.pickingQueue.isAtEnd();
    },
    isAtBeginningOfPickingQueue({ context }) {
      return context.pickingQueue.isAtBeginning();
    },
  },
  actors: {
    fetchBundles: fromPromise(
      async (args: {
        input: { venueId: string };
      }): ReturnType<BundlePickerApiRequirements["fetchBundles"]> => {
        throw new NotImplementedError();
      }
    ),
    fetchVenue: fromPromise(
      async (args: {
        input: { venueId: string };
      }): ReturnType<BundlePickerApiRequirements["fetchVenue"]> => {
        throw new NotImplementedError();
      }
    ),
  },
}).createMachine({
  type: "parallel",
  context: ({ input }) => ({
    venueId: input.venueId,
    changes: createChangeState(new Map() as VariantPicks),
    bundles: new Map(),
    bundleLines: new Map(),
    bundleLineVariants: new Map(),
    pickingQueue: PickingQueue.empty(),
  }),
  states: {
    background: {
      type: "parallel",
      states: {
        venue: {
          initial: "fetching",
          states: {
            idle: { on: { fetchVenue: "fetching" } },
            error: { on: { fetchVenue: "fetching" } },
            fetching: {
              invoke: {
                src: "fetchVenue",
                input: ({ context }) => ({ venueId: context.venueId }),
                onDone: {
                  actions: assign(({ event }) => {
                    if (event.output) {
                      return { venue: event.output };
                    }

                    return {};
                  }),
                },
                onError: "idle",
              },
            },
          },
        },
        bundles: {
          initial: "fetching",
          states: {
            idle: {
              on: {
                fetchBundles: "fetching",
              },
            },
            error: {
              on: {
                fetchBundles: "fetching",
              },
            },
            fetching: {
              invoke: {
                src: "fetchBundles",
                input: ({ context }) => ({ venueId: context.venueId }),
                onDone: {
                  target: "idle",
                  actions: assign(({ event }) => {
                    const indexes: Partial<BundlePickerContext> = {
                      bundles: indexBundles(event.output),
                      bundleLines: indexBundleLines(event.output),
                      bundleLineVariants: indexBundleLineVariants(event.output),
                    };

                    return indexes;
                  }),
                },
                onError: "error",
              },
            },
          },
        },
      },
    },
    modal: {
      initial: "closed",
      states: {
        closed: {
          on: {
            openCartSummary: "cartSummary",
          },
        },
        cartSummary: {
          on: {
            closeCartSummary: "closed",
          },
        },
      },
    },
    ui: {
      initial: "pickingBundle",
      states: {
        pickingBundle: {
          on: {
            pickBundle: {
              actions: [
                assign(({ context, event }) => {
                  const bundle = context.bundles.get(event.data);
                  if (bundle) {
                    const pickingQueue = new PickingQueue(
                      bundle.lines.map((l) => l.id)
                    );
                    const bundleLineId = pickingQueue.current;
                    assert(bundleLineId, "no bundle line id for current");

                    return {
                      bundleId: event.data,
                      pickingQueue: pickingQueue,
                      bundleLineId: bundleLineId,
                      bundleLine: context.bundleLines.get(bundleLineId),
                    };
                  }

                  return {};
                }),
                raise({ type: "next" }),
              ],
            },
            next: {
              target: "pickingVariants",
              guard({ context }) {
                return !context.pickingQueue.empty();
              },
            },
          },
        },
        pickingVariants: {
          on: {
            change: {
              actions: assign(({ context, event }) => {
                return {
                  changes: {
                    ...change(
                      context.changes,
                      setupApplyVariantPickChange(
                        context.bundleLines,
                        context.bundleLineVariants
                      ),
                      event.data
                    ),
                  },
                };
              }),
            },
            cancel: {
              target: "pickingBundle",
              actions: assign(() => resetPicks()),
            },
            prevLine: {
              actions: assign(({ context }) => {
                const bundleLineId = context.pickingQueue.prev();
                assert(bundleLineId, "bundleLineId is missing");
                const bundleLine = context.bundleLines.get(bundleLineId);
                return { bundleLineId, bundleLine };
              }),
            },
            nextLine: {
              actions: [
                assign(({ context }) => {
                  const bundleLineId = context.pickingQueue.next();
                  assert(bundleLineId, "bundleLineId is missing");
                  const bundleLine = context.bundleLines.get(bundleLineId);
                  return { bundleLineId, bundleLine };
                }),
              ],
            },
            validate: {
              actions: enqueueActions(({ enqueue, check, context }) => {
                if (check("isAtEndOfPickingQueue")) {
                  assert(
                    context.bundleId,
                    `missing bundleId in order to emit "bundlePicked"`
                  );
                  enqueue.emit({
                    type: "bundlePicked",
                    data: {
                      id: context.bundleId,
                      picks: context.changes.value,
                    },
                  });
                  enqueue.raise({ type: "finishPicking" });
                }
              }),
            },
            finishPicking: {
              target: "pickingBundle",
              guard: "isAtEndOfPickingQueue",
            },
          },
          back: assign(() => resetPicks()),
        },
      },
    },
  },
});
