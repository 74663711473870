import { assign, setup } from "xstate";
import { BundleId, Quantity, VariantPicks } from "../models";
import { change, ChangeState, createChangeState } from "@greeter/changes";
import { assert } from "../assert";
import { ImageAsset } from "@greeter/core";

function sumTotal(items: CartItems) {
  let total = 0;

  for (const item of items.values()) {
    console.log(`[sumTotal] item`, item.quantity, item.price);
    assert(typeof item.price === "number", "price is not of type 'number'");
    assert(
      typeof item.quantity === "number",
      "quantity is not of type 'number'"
    );
    assert(!isNaN(item.quantity));
    assert(!isNaN(item.price));
    total += item.quantity * item.price;
  }

  return total;
}

function countQuantity(items: CartItems) {
  let total = 0;

  for (const item of items.values()) {
    total += item.quantity;
  }

  return total;
}

export type CartItemId = string;

export type CartItemBundle = {
  type: "bundle";
  id: CartItemId;
  bundleId: BundleId;
  title: string;
  picks: VariantPicks;
  quantity: Quantity;
  coverAsset?: ImageAsset;
  price: number;
};

export type CartItemSimple = {};

export type CartItem = CartItemBundle;

export type CartItems = Map<CartItemId, CartItem>;

export type CartChanges =
  | { type: "add"; data: CartItem }
  | { type: "del"; data: CartItemId }
  | { type: "inc"; data: CartItemId }
  | { type: "dec"; data: CartItemId };

function applyCartChange(s: CartItems, c: CartChanges) {
  switch (c.type) {
    case "add":
      s.set(c.data.id, c.data);
      break;

    case "del":
      s.delete(c.data);
      break;

    case "inc": {
      const cartItem = s.get(c.data);
      if (cartItem) {
        cartItem.quantity++;
      }
      break;
    }
    case "dec": {
      const cartItem = s.get(c.data);
      if (cartItem) {
        cartItem.quantity--;
      }
      break;
    }
  }
}

export type CartContext = {
  changes: ChangeState<Map<CartItemId, CartItem>, CartChanges>;
  total: number;
  count: number;
};

export const cartMachine = setup({
  types: {} as {
    events: { type: "change"; data: CartChanges };
    context: CartContext;
  },
}).createMachine({
  initial: "open",

  context: {
    changes: createChangeState(new Map()),
    total: 0,
    count: 0,
  },

  states: {
    open: {
      on: {
        change: {
          actions: assign(({ context, event }) => {
            const changed = change(
              context.changes,
              applyCartChange,
              event.data
            );
            const total = sumTotal(context.changes.value);
            const count = countQuantity(context.changes.value);
            return {
              changes: { ...changed },
              total,
              count,
            };
          }),
        },
      },
    },
    locked: {},
  },
});
