import {
  AreaWeeklyOpeningHours,
  Booking,
  Bundle,
  emptyBundle,
  FloorPlan,
  GreeterEvent,
  Table,
  Venue,
  WeeklyOpeningHours,
} from "@greeter/core";
import { BookingMachineApiRequirements } from "./CreateBookingMachineV2";
import * as uuid from "uuid";
import { PagedByVenueRequest } from "@greeter/api";
import { addDays, nextFriday } from "date-fns";
import { Day, TimeOfDay } from "@greeter/date";

export class MockGuestApiForTesting implements BookingMachineApiRequirements {
  bundles: Map<string, Bundle> = new Map();
  bookings: Map<string, Booking> = new Map();

  constructor() {
    const _default: Bundle = {
      ...emptyBundle(),
      title: "Intoxication",
      price: 1499,
      coverUrl:
        "https://img.greeter.dk/gsF6d2LwT2h3KS9Cb8usTKrXFLf97_0QmlvOqEA9dos/rs:fill:512:385/g:ce/plain/https://greeterprodsa.blob.core.windows.net/assets/76dcab31-814c-41e5-92fe-4310476a2595@webp",
      coverAsset: {
        uri: "https://img.greeter.dk/gsF6d2LwT2h3KS9Cb8usTKrXFLf97_0QmlvOqEA9dos/rs:fill:512:385/g:ce/plain/https://greeterprodsa.blob.core.windows.net/assets/76dcab31-814c-41e5-92fe-4310476a2595@webp",
        id: "",
        path: "",
        name: "",
        root: "",
        sizes: [],
      },
      discountRate: 25,
      lines: [
        {
          id: uuid.v4(),
          quantity: 1,
          name: "Spiritus",
          variations: [
            {
              id: uuid.v4(),
              name: "0,7L Smirnoff Vodka",
              productId: uuid.v4(),
              additionalPrice: { amount: 0 },
            },
            {
              id: uuid.v4(),
              name: "1,5L Smirnoff Vodka",
              productId: uuid.v4(),
              additionalPrice: { amount: 100 },
            },
            {
              id: uuid.v4(),
              name: "3L Smirnoff Vodka",
              productId: uuid.v4(),
              additionalPrice: { amount: 300 },
            },
          ],
        },
        {
          id: uuid.v4(),
          quantity: 1,
          name: "Shots",
          variations: [
            {
              id: uuid.v4(),
              name: "Små Sure",
              productId: uuid.v4(),
              additionalPrice: { amount: 0 },
            },
            {
              id: uuid.v4(),
              name: "Små Blå",
              productId: uuid.v4(),
              additionalPrice: { amount: 100 },
            },
            {
              id: uuid.v4(),
              name: "Små Fugle",
              productId: uuid.v4(),
              additionalPrice: { amount: 300 },
            },
          ],
        },
      ],
    };
    const bundles = [
      { ..._default, id: uuid.v4() },
      {
        ..._default,
        id: uuid.v4(),
        title: "Down with the sickness",
        price: 1999,
      },
      {
        ..._default,
        id: uuid.v4(),
        title: "Up with the hypeness",
        price: 2999,
      },
    ];

    for (const bundle of bundles) {
      this.bundles.set(bundle.id, bundle);
    }
  }
  async fetchUpcomingGreeterEvents(
    request: PagedByVenueRequest
  ): Promise<Array<GreeterEvent>> {
    const n = new Date();
    const start = nextFriday(n);
    const end = addDays(start, 1);
    return [
      {
        ...GreeterEvent.createDefault(),
        coverAsset: {
          uri: "https://placeholdr.ai/511574bf-f101-41bc-8d45-6b3071e66477/512/512",
          id: "",
          path: "",
          name: "",
          root: "",
          sizes: [],
        },
        name: "Område 69 ",
        description: `Er du klar til at skrue op for festen? Område 69 indtager scenen med hits som "Amar' Ting" og "VARM". Glem blødsøden pop – det er tid til rap, der rykker! Book nu og vær med til at vælte huset! 🎤🔥🎉`,
        startsAt: start,
        endsAt: end,
      },
    ];
  }

  async fetchSpecialOpeningHours(venueId: string) {
    return [];
  }

  async fetchBookingSettings(venueId: string) {
    return { buffer: TimeOfDay.parse("01:00") };
  }

  async fetchBooking(bookingId: string) {
    const booking = this.bookings.get(bookingId);
    if (!booking) {
      throw new Error();
    }
    return booking;
  }

  async fetchLockedTables(venueId: string) {
    return [];
  }

  async fetchBookedTables(venueId: string) {
    return [];
  }

  async fetchBundles(venueId: string): Promise<Array<Bundle>> {
    return Array.from(this.bundles.values());
  }

  async fetchVenue(venueId: string): Promise<Venue> {
    return {
      ...Venue.createDefault(),
      coverAsset: {
        id: "the-cover",
        uri: "https://placeholdr.ai/3d5db17c-6e43-41bc-b1d0-527ec9d7c697/1024/800",
        path: "",
        name: "",
        root: "",
        sizes: [],
      },
      bookableOpeningHours: new WeeklyOpeningHours()
        .set(Day.Thursday, {
          from: TimeOfDay.parse("20:00"),
          to: TimeOfDay.parse("02:00"),
          isOpen: true,
        })
        .set(Day.Friday, {
          from: TimeOfDay.parse("20:00"),
          to: TimeOfDay.parse("02:00"),
          isOpen: true,
        })
        .set(Day.Saturday, {
          from: TimeOfDay.parse("20:00"),
          to: TimeOfDay.parse("02:00"),
          isOpen: true,
        }),
    };
  }

  async fetchFloorPlan(venueId: string): Promise<FloorPlan> {
    const table: Table = {
      type: "table",
      number: 1,
      minCapacity: 2,
      maxCapacity: 4,
      minPrice: 0,
    };

    return {
      areas: [
        {
          floorPlanUrl: "",
          name: "Bier Halle",
          tables: [
            { ...table },
            { ...table, number: 2 },
            { ...table, number: 3 },
            { ...table, number: 4 },
            { ...table, minCapacity: 6, maxCapacity: 10, number: 5 },
          ],
          openingHours: AreaWeeklyOpeningHours.alwaysOpen(),
        },
      ],
    };
  }
}
